
import { defineComponent } from 'vue'
import RegistrationStepTwoSection from '@/components/sections/RegistrationStepTwoSection.vue'

export default defineComponent({
  name: 'RegistrationStepTwo',
  components: {
    RegistrationStepTwoSection
  }
})
